import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Grid, Button, Modal, Box, Tab, Tabs } from "@material-ui/core";
import { Stack } from "@mui/material";

import { resetUserProfile, listUserProfile } from "store/actions";
// import TransferCrypto from "../homepage/transferCrypto";
// import QrModal from "./QrModal";
// import BuyModal from "./BuyModal";
// import SwapModal from "./SwapModal";
import TabContent from "./tabContent";
import UpdateProfile from "./updateProfileModal";
import { history } from "utils";

const Loginbox = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 430px;
    outline: none;

    @media screen and (max-width: 500px) {
        width: 350px;
    }
`;

const BoxDiv = styled.div`
    padding: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: rgb(16, 20, 39, 0.8);
    border-radius: 15px;
`;

const ProfilePic = styled.div`
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(16, 20, 39, 0.8);
    font-weight: bolder;
    background-color: rgb(255, 255, 255, 0.8);
    border-radius: 50%;
    text-transform: uppercase;
`;

function ProfilePage() {
    const dispatch = useDispatch();

    // const [qrModal, setQrModal] = useState(false);
    // const [qrDetails, setQrDetails] = useState({});
    // const [buyModal, setBuyModal] = useState(false);
    // const [swapModal, setSwapModal] = useState(false);
    const [tab, setTab] = useState("solana");
    const [updateModal, setUpdateModal] = useState(false);

    const { userProfile } = useSelector(({ user: { profile } }) => ({
        userProfile: profile,
    }));
    
    useEffect(() => {
        dispatch(resetUserProfile());
        dispatch(listUserProfile());
    }, []);

    // const handleQrModal = (network) => {
    //     // const wallet = userProfile?.wallets ? userProfile?.wallets.filter((e) => e.network === network) : [];
    //     // if (wallet.length) {
    //     //     setQrDetails({ network: network, address: wallet[0].wallet_address });
    //     //     setQrModal(true);
    //     // }
    //     setQrModal(true);
    // };

    const handleTabs = (event, newValue) => {
        setTab(newValue);
    };

    const handleModalClose = () => {
        setUpdateModal(false);
    };

    return (
        <Box sx={{ mt: 10, width: "100%" }}>
            <Grid container spacing={2} justify="center">
                <Grid item lg={6} md={12} xs={12}>
                    <BoxDiv>
                        <Grid container spacing={2} justify="center" alignItems="center">
                            <Grid item lg={2} xs={3} style={{ display: "flex", justifyContent: "center" }}>
                                {userProfile?.name ? (
                                    <ProfilePic>
                                        <h3>
                                            <strong>{Array.from(userProfile?.name)[0]}</strong>
                                        </h3>
                                    </ProfilePic>
                                ) : (
                                    <i className="fa fa-user-circle" style={{ fontSize: "50px" }} />
                                )}
                            </Grid>
                            <Grid item lg={10} xs={9}>
                                <h5>
                                    {userProfile?.name}
                                    <Button
                                        onClick={() => {
                                            setUpdateModal(true);
                                        }}
                                    >
                                        <i className="fas fa-edit" style={{ fontSize: "20px", color: "white" }} />
                                    </Button>
                                </h5>
                                <p style={{ fontSize: 17 }}>{userProfile?.email}</p>
                                <h5> {userProfile?.user_name ?? "-"}</h5>
                                <br/>
                            </Grid>
                            <Grid item lg={3} xs={3}>
                                <Stack alignItems="center" spacing={1}>
                                    <Button
                                        variant="contained"
                                        style={{ width: "50px", height: "60px", borderRadius: "50%", display: "grid", placeItems: "center" }}
                                        onClick={() => history.push(`/app/receive/${userProfile?.user_name}`)}
                                    >
                                        <i className="fas fa-arrow-circle-down" style={{ fontSize: "20px" }} />
                                    </Button>
                                    <b> RECEIVE </b>
                                </Stack>
                            </Grid>
                            <Grid item lg={3} xs={3}>
                                <Stack alignItems="center" spacing={1}>
                                    <Button
                                        variant="contained"
                                        style={{ width: "50px", height: "60px", borderRadius: "50%", display: "grid", placeItems: "center" }}
                                        onClick={() => history.push("/app/transfer")}
                                    >
                                        <i className="fas fa-arrow-circle-up" style={{ fontSize: "20px" }} />
                                    </Button>
                                    <b> TRANSFER </b>
                                </Stack>
                            </Grid>
                            <Grid item lg={3} xs={3}>
                                <Stack alignItems="center" spacing={1}>
                                    <Button
                                        variant="contained"
                                        style={{ width: "50px", height: "60px", borderRadius: "50%", display: "grid", placeItems: "center" }}
                                        onClick={() => history.push("/app/buy")}
                                    >
                                        <i className="fas fa-sync" style={{ fontSize: "20px" }} />
                                    </Button>
                                    <b> BUY </b>
                                </Stack>
                            </Grid>
                            <Grid item lg={3} xs={3}>
                                <Stack alignItems="center" spacing={1}>
                                    <Button
                                        variant="contained"
                                        style={{ width: "50px", height: "60px", borderRadius: "50%", display: "grid", placeItems: "center" }}
                                        onClick={() => history.push("/app/swap")}
                                    >
                                        <i className="fas fa-retweet" style={{ fontSize: "20px" }} />
                                    </Button>
                                    <b> SWAP </b>
                                </Stack>
                            </Grid>
                        </Grid>
                    </BoxDiv>
                </Grid>

                <Grid item lg={6} md={12} xs={12}>
                    <BoxDiv style={{ padding: 0 }}>
                        <Box sx={{ width: "100%" }}>
                            <Stack>
                                <Tabs value={tab || "ethereum"} onChange={handleTabs} centered>
                                    <Tab value="solana" label="Solana chain" />
                                    <Tab value="polygon" label="Polygon chain" />
                                    {/* <Tab value="ethereum" label="Ethereum chain" /> */}
                                </Tabs>
                                {tab === "solana" ? (
                                    <>
                                        <TabContent
                                            dispatch={dispatch}
                                            data={
                                                userProfile?.wallets
                                                    ? userProfile.wallets.filter((e) =>
                                                        ["solana", "sol_usdc", "sol_usdt"].includes(e.network)
                                                    )
                                                    : []
                                            }
                                        />
                                    </>
                                ) :
                                    tab === "ethereum" ? (
                                        <TabContent
                                            dispatch={dispatch}
                                            data={
                                                userProfile?.wallets
                                                    ? userProfile.wallets.filter(
                                                        (e) => ["ethereum", "avalanche", "polygon", "eth_usdc"].includes(e.network)
                                                    )
                                                    : []
                                            }
                                        />
                                ) :
                                    tab === "polygon" ? (
                                        <TabContent
                                            dispatch={dispatch}
                                            data={
                                                    userProfile?.wallets
                                                    ? userProfile.wallets.filter(
                                                        (e) => ["polygon_matic", "polygon_usdc", "polygon_usdt"].includes(e.network)
                                                    )
                                                    : []
                                            }
                                        />
                                    ) :
                                    null}
                            </Stack>
                        </Box>
                    </BoxDiv>
                </Grid>

                <Modal open={updateModal} onClose={handleModalClose}>
                    <Loginbox>
                        <UpdateProfile name={userProfile?.name} handleModal={handleModalClose} />
                    </Loginbox>
                </Modal>

                {/* <Modal open={qrModal} onClose={() => setQrModal(false)}>
                    <Loginbox>
                        <QrModal
                            userName={userProfile?.user_name}
                            details={qrDetails}
                            handleModal={() => setQrModal(false)}
                        />
                    </Loginbox>
                </Modal> */}

                {/* <Modal open={buyModal} onClose={() => setBuyModal(false)}>
                    <Loginbox>
                        <BuyModal dispatch={dispatch} setBuyModal={setBuyModal} />
                    </Loginbox>
                </Modal> */}

                {/* <Modal open={swapModal} onClose={() => setSwapModal(false)}>
                    <Loginbox>
                        <SwapModal dispatch={dispatch} setSwapModal={setSwapModal} />
                    </Loginbox>
                </Modal> */}
            </Grid>
        </Box>
    );
}

export default ProfilePage;
