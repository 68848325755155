import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { EmptyBox } from "components/StyledComponents";
import { getBlockchainName, getBlockchainNetwork } from "utils";
import { resetAllPaymentReqs, listAllPaymentReqs } from "store/actions";

import ExpandableListView from "components/ExpandableListView/ExpandableListView";
import { history } from "utils";

function Received() {
    const dispatch = useDispatch();

    const { data, showViewMore } = useSelector(({ paymentReqs: { list, showViewMore } }) => ({
        data: list,
        showViewMore: showViewMore,
    }));

    useEffect(() => {
        dispatch(resetAllPaymentReqs());
        fetchUserTxns();
    }, []);

    const fetchUserTxns = () => {
        dispatch(listAllPaymentReqs({ type: "received" }));
    };

    const colorIndicator = (element, arr = [], style = {}) => {
        arr.map((e) => {
            if (element[e.field] === e.value) {
                style = { color: e.color };
            }
            return null;
        });
        return style;
    };

    return (
        <>
            {data ? (
                <ExpandableListView
                    pairs={[
                        { key: "REQUEST ID", value: "req_id" },
                        {
                            key: "Status",
                            render: (e) => (
                                <div
                                    style={colorIndicator(e, [
                                        { field: "status", value: "paid", color: "#6bc654" },
                                        { field: "status", value: "rejected", color: "red" },
                                        { field: "status", value: "pending", color: "#a1a1a1" },
                                    ])}
                                >
                                    {e.status}
                                </div>
                            ),
                        },
                        {
                            key: "Amount",
                            render: (rowData) => `${rowData?.amount} ${rowData?.currency.toUpperCase()}`,
                        },
                        { key: "Network", render: () => "solana" },
                        // { key: "Network", render: (rowData) => getBlockchainName(rowData?.chain_network) },
                        { key: "Type", value: "type" },
                        {
                            key: "Created At",
                            render: (rowData) => moment.unix(rowData?.created_at).format("DD-MM-YYYY hh:mm A"),
                        },
                        {
                            key: "Received from email",
                            render: (rowData) => (rowData?.to_uid ? rowData.from_uid?.email : "-"),
                        },
                        {
                            key: "Received from user",
                            render: (rowData) => (rowData?.to_uid ? rowData.from_uid?.name : "-"),
                        },
                    ]}
                    leftTitle={[
                        {
                            key: "chain_network",
                            render: (e) => `${getBlockchainName(e?.chain_network)}`,
                        },
                        {
                            key: "created_at",
                            render: (e) => <>{moment.unix(e?.created_at).format("DD-MM-YYYY hh:mm A")}</>,
                        },
                    ]}
                    rightTitle={{
                        main: {
                            render: (e) => (
                                <b
                                // style={colorIndicator(e, [
                                //     { field: "type", value: "received", color: "red" },
                                //     { field: "type", value: "requested", color: "#6bc654" },
                                // ])}
                                >
                                    {e?.amount} {e?.currency.toUpperCase()}
                                </b>
                            ),
                        },
                        sub: {
                            render: (e) => (
                                <div
                                    style={colorIndicator(e, [
                                        { field: "status", value: "paid", color: "#6bc654" },
                                        { field: "status", value: "rejected", color: "red" },
                                        { field: "status", value: "pending", color: "#a1a1a1" },
                                    ])}
                                >
                                    {e.status}
                                </div>
                            ),
                        },
                    }}
                    data={data}
                    iconImg={{ render: (e) => `${getBlockchainNetwork(e.chain_network)}` }}
                    showViewMoreButton={showViewMore}
                    viewMoreButtonClick={fetchUserTxns}
                    endButton={{ title: "open request", action: (e) => history.push(`/app/request/${e?.req_id}`) }}
                />
            ) : (
                <EmptyBox>No data found</EmptyBox>
            )}
        </>
    );
}

export default Received;
