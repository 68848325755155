import solana from "../assets/icons/solana.png";
import etherium from "../assets/icons/ethereum.png";
import avalanche from "../assets/icons/avalanche.png";
import polygon from "../assets/icons/polygon.png";
import usdc from "../assets/icons/usdc.png";
import usdt from "../assets/icons/usdt.png";

export const getBlockchainNetwork = (currency = "sol") => {
    if (currency === "sol" || currency === "solana") {
        return solana;
    } else if (currency === "eth" || currency === "ethereum") {
        return etherium;
    } else if (currency === "avax" || currency === "avalanche") {
        return avalanche;
    } else if (["matic", "polygon", "polygon_matic"].includes(currency)) {
        return polygon;
    } else if (["eth_usdc", "sol_usdc", "usdc", "USDC", "polygon_usdc"].includes(currency)) {
        return usdc;
    } else if (["sol_usdt", "USDT", "polygon_usdt"].includes(currency)) {
        return usdt;
    } else {
        return solana;
    }
};

export const getBlockchainCurrency = (network = "solana") => {
    if (network === "solana") {
        return "SOL";
    } else if (network === "ethereum") {
        return "ETH";
    } else if (network === "avalanche") {
        return "AVAX";
    } else if (network === "polygon" || network === "polygon_matic") {
        return "MATIC";
    } else if (network === "eth_usdc" || network === "sol_usdc" || network === "polygon_usdc") {
        return "USDC";
    } else if (network === "sol_usdt" || network === "polygon_usdt") {
        return "USDT";
    } else {
        return "SOL";
    }
};

export const getBlockchainName = (network = "solana") => {
    if (network === "solana") {
        return "solana";
    } else if (network === "ethereum") {
        return "ethereum";
    } else if (network === "avalanche") {
        return "avalanche";
    } else if (network === "polygon") {
        return "polygon";
    } else if (network === "polygon_matic") {
        return "matic";
    } else if (network === "eth_usdc" || network === "sol_usdc" || network === "polygon_usdc") {
        return "USDC";
    } else if (network === "sol_usdt" || network === "polygon_usdt") {
        return "USDT";
    } else {
        return "---";
    }
};
