import React from "react";
import { useDispatch } from "react-redux";
import { Box, Button, FilledInput, InputAdornment, CardContent } from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import { People } from "@material-ui/icons";

import { updateUserProfile } from "store/actions";

function UpdateProfile({ name = "", handleModal }) {
    const dispatch = useDispatch();

    return (
        <>
            <Box style={{ padding: 8, borderRadius: "10px", backgroundColor: "#181529", boxShadow: "0 3px 70px #000000" }}>
                <div style={{ textAlign: "center", marginTop: "20px", fontSize: "17px", color: "white" }}>
                    <p>UPDATE NAME</p>
                </div>
                <CardContent>
                    <Formik
                        initialValues={{
                            name: name,
                        }}
                        validationSchema={Yup.object().shape({
                            name: Yup.string().required("Name is required"),
                        })}
                        onSubmit={async (value) => {
                            handleModal();
                            dispatch(updateUserProfile(value));
                        }}
                    >
                        {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
                            <form onSubmit={handleSubmit}>
                                <FilledInput
                                    autoComplete="off"
                                    type="text"
                                    fullWidth
                                    placeholder="Enter Name"
                                    name="name"
                                    defaultValue={values.name}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <People />
                                        </InputAdornment>
                                    }
                                    required={true}
                                    error={Boolean(touched.name && errors.name)}
                                />
                                {Boolean(touched.name && errors.name) ? (
                                    <div
                                        style={{
                                            display: "block",
                                            marginLeft: "10px",
                                            color: "red",
                                            fontSize: 13,
                                        }}
                                    >
                                        {errors.name}
                                    </div>
                                ) : (
                                    ""
                                )}
                                <Box textAlign="center" marginTop="1.5rem">
                                    <Button
                                        fullWidth
                                        id="signin-btn"
                                        color="primary"
                                        variant="contained"
                                        onClick={handleSubmit}
                                        disabled={values.name === ""}
                                    >
                                        Update
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </CardContent>
            </Box>
        </>
    );
}

export default UpdateProfile;
