import React, { useEffect, useState } from "react";

import { Button, Box, Modal } from "@material-ui/core";
import styled from "styled-components";
import OtpModalContent from "./OtpModalContent";

import { Stack } from "@mui/system";
import { Card, Typography } from "@mui/material";

import { toggleLoading } from "store/actions";
import { CHECK_FOR_PAYMENT_REQUEST } from "graphql";
import { unAuthorizedPost } from "services";

const Loginbox = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 430px;
    outline: none;

    @media screen and (max-width: 500px) {
        width: 350px;
    }
`;

const Wallet = styled.div`
    width: 100%;
    white-space: nowrap;
    font-weight: 600;
    overflow: hidden !important;
    text-overflow: ellipsis;
`;

const styles = {
    card: {
        p: 3, 
        color: "#666",
        borderRadius: "10px", 
    },
    label: {
        textTransform: 'uppercase', 
        fontSize: "12px",
    },
    databox: {
        p: 1, 
        border: "1px solid #ccc", 
        borderRadius: "5px", 
        textAlign: "right",
        boxShadow: "0 2px 2px rgb(0 0 0 / 0.2)",
        overflow: "hidden !important", 
    },
    message: {
        p: 1, 
        width: "100%", 
        bgcolor: "#f0f0f0", 
        borderRadius: "10px",
    }
}

const FinalPage = ({ reqId, dispatch, token, currency, data, updateToken }) => {
    const [details, setDetails] = useState({});
    const [apiCallCount, setApiCallCount] = useState(0);
    const [isBalSufficient, setIsBalSufficient] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        fetchPaymentDetails();
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (!isBalSufficient) {
                fetchPaymentDetails();
            }
        }, 15000);

        return () => clearTimeout(timer);
    }, [apiCallCount]);

    const fetchPaymentDetails = async () => {
        dispatch(toggleLoading(true));

        const apiResponse = await unAuthorizedPost("", {
            query: CHECK_FOR_PAYMENT_REQUEST,
            variables: { reqId: reqId },
        });
        setApiCallCount(apiCallCount + 1);

        if (apiResponse.data.customStatus) {
            let { check_for_pmt_req_txn } = apiResponse.data.data;

            setDetails(check_for_pmt_req_txn);
            setIsBalSufficient(check_for_pmt_req_txn?.is_bal_sufficient);
        }
        dispatch(toggleLoading(false));
    };

    return (
        <>
            <Card sx={styles.card}>
                <Stack spacing={2}>
                    <Stack>
                        <Typography variant="subtitle2" sx={styles.label}>
                            <b>Request ID</b>
                        </Typography>
                        <Box sx={styles.databox}>
                            <Typography variant="body2"><b>{reqId ?? "-"}</b></Typography>
                        </Box>
                    </Stack>

                    <Stack>
                        <Typography variant="subtitle2" sx={styles.label}>
                            <b>Account Balance</b>
                        </Typography>
                        <Box sx={styles.databox}>
                            <Typography variant="body2">
                                <b>
                                    {details?.acc_balance ?? "-"} {currency ? currency.toUpperCase() : ""}
                                </b>
                            </Typography>
                        </Box>
                    </Stack>

                    <Stack>
                        <Typography variant="subtitle2" sx={styles.label}>
                            <b>Amount Required</b>
                        </Typography>
                        <Box sx={styles.databox}>
                            <Typography variant="body2">
                                <b>{details?.amount_required ?? "-"} {currency ? currency.toUpperCase() : ""}</b>
                            </Typography>
                        </Box>
                    </Stack>

                    <Stack>
                        <Typography variant="subtitle2" sx={styles.label}>
                            <b>Wallet Address</b>
                        </Typography>
                        <Stack direction="row" spacing={1}>
                            <Box sx={styles.databox}>
                                <Wallet>{details?.wallet_address ?? "-"}</Wallet>
                            </Box>
                            <Button
                                variant="contained"
                                size="small"
                                onClick={() => {
                                    setCopied(true)
                                    setInterval(() => setCopied(false), 5000);
                                    navigator.clipboard.writeText(details?.wallet_address)
                                }}>
                                {copied ? "copied" : "copy"}
                            </Button>
                        </Stack>
                    </Stack>

                    {!isBalSufficient ? 
                    <Stack>
                        <Box sx={styles.message}>
                        You need to carry out a transaction of {" "}
                        <b> {details?.amount_required ?? "-"} {currency ? currency.toUpperCase() : ""} </b> to the above wallet address.<br/> 
                        Once you have loaded the wallet enter OTP to complete the transaction.
                        </Box>
                    </Stack> 
                    : null}
                    
                    <Stack direction="row" spacing={2}>
                        {/* <Button fullWidth variant="outlined" onClick={() => handleSubmit("reject")}>
                            Reject
                        </Button> */}
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={() => setShowModal(true)}
                            disabled={!isBalSufficient ?? false}
                            style={{ color: !isBalSufficient ? "grey" : "" }}
                        >
                            Pay now
                        </Button>
                    </Stack>
                </Stack>
            </Card>
            <Modal open={showModal} onClose={() => setShowModal(false)}>
                <Loginbox>
                    {
                        <OtpModalContent
                            handleModal={() => setShowModal(false)}
                            dispatch={dispatch}
                            reqId={reqId}
                            email={data?.email ?? ""}
                            currency={data?.currency}
                        />
                    }
                </Loginbox>
            </Modal>
        </>
    );
};

export default FinalPage;
