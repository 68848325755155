import React from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { Icon } from '@iconify/react';

function FeaturesSection() {
    const featuresData = [
        {
            icon: "fluent:cursor-click-24-filled",
            title: "1-Click sign up",
            description: "Easy sign up with an email ID to start collecting payments from your customers"
        },
        {
            icon: "mingcute:receive-money-fill",
            title: "Receive from any wallet",
            description: "Your customers can pay you from any crypto wallet using the top 20+ currencies",
        },
        {
            icon: "clarity:wallet-solid",
            title: "Multi-chain wallet",
            description: "Keep more than one crypto asset belonging to multiple blockchain networks",
        },
        {
            icon: "bxs:user-check",
            title: "No KYC",
            description: "Start receiving payment without any complex KYC verification hassle",
        },
        {
            icon: "dashicons:admin-links",
            title: "Dynamic payment Links",
            description: "Dynamic payment links for added layer of security & simplicity",
        }, {
            icon: "bxs:shopping-bags",
            title: "eCommerce",
            description: "Add Tokenlist to your ecommerce as your crypto payment gateway",
        },
        {
            icon: "bxs:chat",
            title: "Transact by Chat",
            description: "Use Telegram app to send & receive payments, when on the move.",
        },
        {
            icon: "ic:baseline-space-dashboard",
            title: "Dashboards",
            description: "Analyze your transactions on our unified business intelligence dashboard & automate reports",
        },
        {
            icon: "mdi:cash-refund",
            title: "Easy cash-out",
            description: "Easily transfer your balances to your existing wallets on your preferred exchange",
        },
        {
            icon: "wpf:security-checked",
            title: "Secured",
            description: "Bank-level security safeguards your Digital Assets from potential threats with password management via OTP",
        },
        {
            icon: "icon-park-solid:transfer",
            title: "No Chargeback",
            description: "Once payment is made, there are no charge backs. Clients follow your refund policy",
        },
        {
            icon: "ant-design:notification-filled",
            title: "Notifications",
            description: "Set up Email and telegram notifications of transactions and newsletters",
        },
    ]

    return (
        <>
            <section style={{ backgroundColor: "#000" }}>
                <div className="container">
                    <Box sx={{ width: "100%" }}>
                        <Stack spacing={7}>
                            <Typography variant="h5" sx={{ textAlign: "center" }}>
                                F E A T U R E S
                            </Typography>
                            <Stack>
                                <Grid container spacing={4} justifyContent="center">
                                    {featuresData.map((e, i) => (
                                        <Grid item lg={4} md={6} xs={10} key={i}>
                                            <Stack direction="row" spacing={2} alignItems="center">
                                                <Box sx={{ width: "70px" }}>
                                                    <Icon icon={e.icon} width="60" color="#B5EAD7" />
                                                </Box>
                                                <Stack spacing={1}>
                                                    <div style={{ color: "#fff" }}>
                                                        <h5>{e.title}</h5>
                                                    </div>
                                                    <Typography variant="body1">
                                                        {e.description}
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Stack>
                        </Stack>
                    </Box>
                </div>
            </section>
        </>
    )
}

export default FeaturesSection