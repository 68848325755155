export const FETCH_ALL_PAYMENT_REQUESTS = `query ($pageSize: Int, $pageNumber: Int, $type: String, $status: String) {
    list_all_payment_requests(page_size: $pageSize, page_number: $pageNumber, type: $type, status: $status) {
      message
      data {
        _id
        req_id
        txn_signature
        type
        amount
        status
        chain_network
        currency
        created_at
        to_uid {
          _id
          name
          email
        }
        from_uid {
          _id
          name
          email
        }
      }
      hasMore
    }
  }
  
  `;

export const FETCH_PAYMENT_REQ_DETAILS = `query ($reqId: String!) {
    list_payment_request_details(req_id:$reqId){
      message
      data {
        _id
        req_id
        status
        txn_signature
        from_user_type
        to_user_type
        amount
        chain_network
        currency
        created_at
        completed_at
        is_payable
        from_uid {
          name
          email
        }
        to_uid {
          name
          email
        }
      }
    }
  }
  `;

export const SEND_PAYMENT_REQ = `mutation ($email: String!, $amount: Float!, $network: String) {
    send_payment_request(email: $email, amount: $amount, network: $network) {
      message
    }
  }
  `;

export const COMPLETE_PAYMENT_REQ = `mutation ($reqId: String!, $isAccepted: Boolean!) {
    complete_payment_request(req_id: $reqId, is_accepted: $isAccepted) {
      message
    }
  }
  `;

export const CHECK_FOR_PAYMENT_REQUEST = `query ($reqId: String!) {
  check_for_pmt_req_txn(req_id: $reqId) {
    amount_required
    is_bal_sufficient
    acc_balance
    wallet_address
  }
}
`;
