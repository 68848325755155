export const TOGGLE_LOADING = "TOGGLE_LOADING";
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const TOGGLE_VIEWMODE = "TOGGLE_VIEWMODE";
export const TOGGLE_MODAL = "TOGGLE_MODAL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";

export const LIST_ALL_USER_TXNS = "LIST_ALL_USER_TXNS";
export const LIST_USER_PROFILE = "LIST_USER_PROFILE";
export const RESET_ALL_USER_TXNS = "RESET_ALL_USER_TXNS";
export const RESET_USER_PROFILE = "RESET_USER_PROFILE";

export const UPDATE_WALLET_BALANCE = "UPDATE_WALLET_BALANCE";

export const LIST_BLOCKCHAIN_TXN_HISTORY = "LIST_BLOCKCHAIN_TXN_HISTORY";
export const RESET_BLOCKCHAIN_TXN_HISTORY = "RESET_BLOCKCHAIN_TXN_HISTORY";

export const LIST_RAMPAY_USER_ID = "LIST_RAMPAY_USER_ID";
export const RESET_RAMPAY_USER_ID = "RESET_RAMPAY_USER_ID";

export const RAMPAY_ORDER_ID = "RAMPAY_ORDER_ID";
export const RESET_RAMPAY_ORDER_ID = "RESET_RAMPAY_ORDER_ID";

export const LIST_ALL_PAYMENT_REQS = "LIST_ALL_PAYMENT_REQS";
export const RESET_ALL_PAYMENT_REQS = "RESET_ALL_PAYMENT_REQS";

export const LIST_PAYMENT_REQ_DETAILS = "LIST_PAYMENT_REQ_DETAILS";
export const RESET_PAYMENT_REQ_DETAILS = "RESET_PAYMENT_REQ_DETAILS";
