import React from 'react';
import { history } from "utils";
import { Button } from "@material-ui/core";

const buttonStyle = {
    backgroundColor: "#B5EAD7",
    color: "black",
    border: 0,
};

function BannerContent() {
    return (
        <>
            <section className="container-fluid banner-container">
                <div className="container">
                    <div className="row banner-row">
                        <div className="col-md-6 banner-txt">
                            <h1><strong style={{ color: "#B5EAD7" }}>Tokenlist</strong> is a social wallet for crypto payments</h1>
                            <p>Tokenlist is built to simplify how crypto and web3 tokens are transacted between users and businesses.</p>
                            <div className="btn-row row">
                                <Button
                                    className="btn btn-primary clr"
                                    style={buttonStyle}
                                    onClick={() => history.push("/auth/home")}
                                >
                                    <b> Sign Up / Sign In</b>
                                </Button>
                            </div>
                        </div>
                        <div className="col-md-6 banner-img">
                            <img src={require("../../assets/bg/crypto-portfolio.svg").default} alt="" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BannerContent