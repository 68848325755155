import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import PaymentRequestDetails from "./RequestDetails";
import FinalPage from "./FinalPage";
import { Box, Grid } from "@mui/material";

const Payments = () => {
    const dispatch = useDispatch();
    const { reqId } = useParams();

    const [page, setPage] = useState(1);
    const [paymentDetails, setPaymentDetails] = useState({
        token: "",
        currency: "",
        email: "",
    });

    const handlePage = (value) => {
        setPage(value);
    };

    const updateDetails = (data) => {
        setPaymentDetails({ ...paymentDetails, ...data });
    };

    return (
        <Box width={"100%"}>
            <Grid container justifyContent="center">
                <Grid item lg={3.5} md={8} sm={10} xs={12}>
                    <Box sx={{ p: 2, mt: 5, width: "100%" }}>
                        {page === 1 ? (
                            <PaymentRequestDetails
                                reqId={reqId}
                                dispatch={dispatch}
                                handlePage={handlePage}
                                updateToken={updateDetails}
                            />
                        ) : page === 2 ? (
                            <FinalPage
                                dispatch={dispatch}
                                reqId={reqId}
                                token={paymentDetails.token}
                                currency={paymentDetails.currency}
                                updateToken={updateDetails}
                                data={paymentDetails}
                            />
                        ) : null}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Payments;
