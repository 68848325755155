import React from "react";
import "./style.css";

import NavBar from "./navBar";
import BannerContent from "./banner-content";
import FeaturesSection from "./features-section";
import SupportingStartups from "./supporting-startups";
import PaymentScreen from "./payment-screen";
import Footer from "./footer";

function Landing() {
    return (
        <div>
            <NavBar />
            <BannerContent />
            <FeaturesSection />
            <SupportingStartups />
            <PaymentScreen />
            <Footer />
        </div>
    );
}

export default Landing;
