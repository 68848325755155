import React, { useState } from "react";
import { Card, CardContent, Grid } from "@material-ui/core";
import VerifyUser from "./verifyUser";
import SendOtp from "./SendOtp";

function Register({ handlePage }) {
    const [email, setEmail] = useState();

    return (
        <>
            <Grid item>
                <Card style={{padding: 8, borderRadius: "10px", backgroundColor: "#101427", boxShadow: "0 3px 70px #000000" }}>
                    <div style={{ textAlign: "center", marginTop: "20px", fontSize: "18px" }}>
                        <p>Sign Up</p>
                    </div>
                    <CardContent>
                        {!email ? <SendOtp setEmail={setEmail} /> : <VerifyUser email={email} handlePage={handlePage} />}
                    </CardContent>
                    {!email ? (
                        <div style={{ textAlign: "center" }}>
                            <p>
                                Already registered?{" "}
                                <strong style={{ color: "#B5EAD7" }} onClick={handlePage}>
                                    sign in here
                                </strong>
                            </p>
                            <br />
                            <p>
                                <a href="/landing">
                                    {/* <strong style={{ color: "white" }}>Home</strong> */}
                                    {/* <Home color="primary"  fontSize="large" /> */}
                                    <i className="fa fa-home" style={{ fontSize: "25px", color: "#B5EAD7" }} />
                                </a>
                            </p>
                        </div>
                    ) : null}
                </Card>
            </Grid>
        </>
    );
}

export default Register;
