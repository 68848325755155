import React, { useState } from 'react'
import { Box, Modal, Stack } from '@mui/material'
import { Button } from '@material-ui/core';
import { getBlockchainNetwork } from 'utils';
import styled from 'styled-components';
import CopyToClipboard from 'react-copy-to-clipboard';
import QRCode from 'react-qr-code';
import { fetchWalletBalance } from 'store/actions';
import { getBlockchainCurrency,getBlockchainName } from 'utils';

const AddressBar = styled.div`
    width: 100%;
    padding: 10px;
    display: inline-block;
    margin: auto;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    font-weight: bold;
    font-size: 17px;
    border: 1px solid #8c8c8c;
    border-radius: 10px;

    @media only screen and (max-width: 600px) {
        font-size: 12px;
    }
`;

const QrBox = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 430px;
    background-color: #fff;
    border-radius: 20px;
    padding: 1.5em;

    @media screen and (max-width: 500px) {
        width: 350px;
    }
`;

function TabContent({ dispatch, data }) {
    const [address, setAddress] = useState("");
    const [isOpen, setIsOpen] = useState(false);

    const handleViewQRutton = (address) => {
        setIsOpen(true)
        setAddress(address)
    }
    const handleBalanceButton = (network) => {
        dispatch(fetchWalletBalance({ network: network }));
    }

    return (
        <>
            <Box sx={{ width: "100%" }}>
                <Stack sx={{ p: 2 }} spacing={2}  >
                    {data.map((e, i) => (
                        <Box key={i} sx={{ p: 2, background: "rgb(130, 130, 130, 0.1)", borderRadius: "10px" }}>
                            <Stack spacing={2}>
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <Stack direction='row' spacing={1} sx={{width: "60%"}}>
                                    <img
                                        style={{ width: 30, height: 30 }}
                                        src={getBlockchainNetwork(e.network)}
                                        alt=""
                                    />
                                    <h4 style={{ textTransform: "capitalize" }}>{getBlockchainName(e.network)}</h4>
                                    </Stack>
                                    <Stack direction='row' justifyContent='flex-end' sx={{width: "100%"}}>
                                    <h6>balance: {" "}
                                    {e.balance != null ? 
                                     <>{e.balance} </> : " - "
                                    }
                                    {getBlockchainCurrency(e.network)}
                                    </h6>
                                    </Stack>
                                </Stack>
                                <Stack direction="row" spacing={1}>
                                    <AddressBar>{e.wallet_address}</AddressBar>
                                    <CopyToClipboard text={e.wallet_address} onCopy={() => setAddress(e.wallet_address)}>
                                        <Button size='small' variant='outlined'>
                                            {address === e.wallet_address ? "Copied" : "Copy"}
                                        </Button>
                                    </CopyToClipboard>
                                </Stack>
                                <Stack direction="row" spacing={2}>
                                    <Button size='small' fullWidth variant='outlined' onClick={() => handleViewQRutton(e.wallet_address)}>
                                        View QR
                                    </Button>
                                    <Button size='small' fullWidth variant='contained' onClick={() => handleBalanceButton(e.network)}>
                                        Show balance
                                    </Button>
                                </Stack>
                            </Stack>
                        </Box>
                    ))}
                </Stack>
            </Box>

            <Modal open={isOpen} onClose={() => setIsOpen(false)}>
                <QrBox>
                    <QRCode
                        value={address}
                        size={256}
                        style={{
                            height: "auto",
                            maxWidth: "100%",
                            width: "100%",
                        }}
                        viewBox={`0 0 256 256`}
                    />
                </QrBox>
            </Modal>
        </>
    )
}

export default TabContent