import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, FilledInput, InputAdornment, CircularProgress as Loader } from "@material-ui/core";
import { Email, FileCopy } from "@material-ui/icons";
import { Stack } from "@mui/material";
import { Formik } from "formik";
import { shareQrLink } from "store/actions";
import CopyToClipboard from "react-copy-to-clipboard";
import * as Yup from "yup";

const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;

function ShareQr({ userName }) {
    const dispatch = useDispatch();
    const [copied, setCopied] = useState(false);

    const { isLoading } = useSelector(({ loading }) => ({
        isLoading: loading,
    }));

    return (
        <>
            <Formik
                initialValues={{
                    email: "",
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string().email().required("Email address is required"),
                })}
                onSubmit={(value) => {
                    // value.network = network;
                    dispatch(shareQrLink(value));
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
                    <form onSubmit={handleSubmit}>
                        <FilledInput
                            autoComplete="on"
                            type="email"
                            fullWidth
                            placeholder="Enter Email Address"
                            name="email"
                            defaultValue={values.email}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            startAdornment={
                                <InputAdornment position="start">
                                    <Email />
                                </InputAdornment>
                            }
                            required={true}
                            error={Boolean(touched.email && errors.email)}
                            disabled={isLoading}
                        />
                        {Boolean(touched.email && errors.email) ? (
                            <div
                                style={{
                                    display: "block",
                                    marginLeft: "10px",
                                    color: "red",
                                    fontSize: 13,
                                }}
                            >
                                {errors.email}
                            </div>
                        ) : (
                            ""
                        )}
                        <Box sx={{ textAlign: "center", color: "#ccc", padding: "10px" }}>
                            <h6>{`${FRONTEND_URL}${userName}`}</h6>
                        </Box>
                        <br />
                        <Box textAlign="center">
                            <Stack direction="row" spacing={1}>
                                <CopyToClipboard text={`${FRONTEND_URL}${userName}`} onCopy={() => setCopied(true)}>
                                    <Button fullWidth variant='outlined'>
                                        {copied ? "Copied" : "Copy"} &nbsp; <FileCopy />
                                    </Button>
                                </CopyToClipboard>
                                <Button fullWidth variant="contained" onClick={handleSubmit}>
                                    {isLoading ? <Loader color="secondary" size={20} /> : "Share QR code"}
                                </Button>
                            </Stack>
                        </Box>
                    </form>
                )}
            </Formik>
        </>
    );
}

export default ShareQr;
