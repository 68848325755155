import React from 'react';
import { Box, Stack } from '@mui/material';
import "./payment-screen.css";
import { Button } from '@material-ui/core';
import paymentScreenImg from "./../../assets/img/landing/payment-screen-enhanced.png";

function PaymentScreen() {
    return (
        <>
            <section style={{ backgroundColor: "#000", marginTop: "1px" }}>
                <div className="container">
                    <Box sx={{ width: "100%", padding: "30px 0" }}>
                        <div className="pscreen">
                            <Stack spacing={4}>
                                <Stack spacing={{ xs: 4, lg: 3 }} direction={{ lg: "row", xs: "column" }} alignItems="center">
                                    <Stack spacing={{ xs: 4, lg: 3 }} style={{ width: "100%" }}>
                                        <Stack alignItems={{ xs: "center", lg: "flex-start" }}>
                                            <h1>
                                                <b>Set up a business account in less than <span>2 minutes</span></b>
                                            </h1>
                                        </Stack>
                                        <Stack alignItems={{ xs: "center", lg: "flex-start" }}>
                                            <ul>
                                                <li>Signup without KYC</li>
                                                <li>Program for you business flows without coding</li>
                                                <li>Setup payment pages, links and checkouts</li>
                                            </ul>
                                        </Stack>
                                        <Stack alignItems={{ xs: "center", lg: "flex-start" }}>
                                            <Button
                                                className="setup-btn"
                                                variant="contained"
                                                onClick={() => window.open("https://business.tokenlist.club/login")}
                                            >
                                                <b>Set Up Now / Sign In</b>
                                            </Button>
                                        </Stack>
                                    </Stack>
                                    <Stack alignItems="center">
                                        <img src={paymentScreenImg} alt="" />
                                    </Stack>
                                </Stack>
                            </Stack>
                        </div>
                    </Box>
                </div>
            </section>
        </>
    )
}

export default PaymentScreen