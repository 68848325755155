import {
    LIST_ALL_PAYMENT_REQS,
    RESET_ALL_PAYMENT_REQS,
    LIST_PAYMENT_REQ_DETAILS,
    RESET_PAYMENT_REQ_DETAILS,
} from "../types";

const INITIAL_STATE = {
    list: [],
    showViewMore: false,
    pageNumber: 1,
    pageSize: 10,
    details: {},
};

const paymentReqReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case LIST_ALL_PAYMENT_REQS:
            return {
                ...state,
                pageSize: 10,
                ...payload,
            };

        case RESET_ALL_PAYMENT_REQS:
            return INITIAL_STATE;

        case LIST_PAYMENT_REQ_DETAILS:
            return {
                ...state,
                details: payload,
            };

        case RESET_PAYMENT_REQ_DETAILS:
            return {
                ...state,
                details: INITIAL_STATE.details,
            };
        default:
            return state;
    }
};

export default paymentReqReducer;
