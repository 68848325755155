import React from "react";

function Footer() {
  return (
    <>
      <div className="copy">
        <div className="container">
          <a href="/">
            2022 © All Rights Reserved | Tokenlist
          </a>
          <span>
            {/* <a href="#">
              <i className="fab fa-discord" />
            </a>
            <a href="#">
              <i className="fab fa-telegram" />
            </a>
            <a href="">
              <i className="fab fa-whatsapp" />
            </a> */}
            <a href="https://twitter.com/tokenlist_">
              <i className="fab fa-twitter" />
            </a>
            <a href="https://www.linkedin.com/company/thetokenlist">
              <i className="fab fa-linkedin" />
            </a>
            <a href="https://www.facebook.com/thetokenlist">
              <i className="fab fa-facebook-f" />
            </a>
          </span>
        </div>
      </div>
    </>
  );
}

export default Footer;
