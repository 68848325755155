import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { Grid, Button, Box, Modal } from "@material-ui/core";
import styled from "styled-components";
import ConfirmModalContent from "./ConfirmModalContent";

import { resetPaymentReqDetails, listPaymentReqDetails } from "store/actions";
import { Stack } from "@mui/system";
import { Typography } from "@mui/material";

const BoxDiv = styled.div`
    width: 100%;
    background-color: rgb(16, 20, 39, 0.8);
    border-radius: 10px;
`;

const Loginbox = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 430px;
    outline: none;

    @media screen and (max-width: 500px) {
        width: 350px;
    }
`;

const RequestDetails = () => {
    const dispatch = useDispatch();
    const { reqId } = useParams();

    const [isAccepted, setIsAccepted] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const { data } = useSelector(({ paymentReqs: { details } }) => ({
        data: details,
    }));

    useEffect(() => {
        dispatch(resetPaymentReqDetails());
        dispatch(listPaymentReqDetails({ reqId: reqId }));
    }, []);

    const handleSubmit = (type) => {
        setIsAccepted(type === "confirm");
        setShowModal(true);
    };

    return (
        <>
            <Box sx={{ mt: 10, width: "100%" }}>
                <Grid container justify="center">
                    <Grid item lg={6} xs={12}>
                        <BoxDiv>
                            <Stack sx={{ p: 2 }}>
                                <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                                    <Typography variant="subtitle1">
                                        <b>Request ID:</b>
                                    </Typography>
                                    <Typography variant="body2">{data?.req_id ?? "-"}</Typography>
                                </Stack>
                                <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                                    <Typography variant="subtitle1">
                                        <b>Amount:</b>
                                    </Typography>
                                    <Typography variant="body2">
                                        <b>
                                            {data?.amount ?? "-"} {data?.currency ? data?.currency.toUpperCase() : ""}
                                        </b>
                                    </Typography>
                                </Stack>
                                <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                                    <Typography variant="subtitle1">
                                        <b>Request from email:</b>
                                    </Typography>
                                    <Typography variant="body2">{data?.from_uid ? data?.from_uid?.email : "-"}</Typography>
                                </Stack>
                                <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                                    <Typography variant="subtitle1">
                                        <b>Request from user:</b>
                                    </Typography>
                                    <Typography variant="body2">{data?.from_uid ? data?.from_uid?.name : "-"}</Typography>
                                </Stack>
                                <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                                    <Typography variant="subtitle1">
                                        <b>Status:</b>
                                    </Typography>
                                    <Typography variant="body2">{data?.status ?? "-"}</Typography>
                                </Stack>
                                <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                                    <Typography variant="subtitle1">
                                        <b>Requested At:</b>
                                    </Typography>
                                    <Typography variant="body2">
                                        {data?.created_at
                                            ? moment.unix(data?.created_at).format("DD-MM-YYYY hh:mm A")
                                            : "-"}
                                    </Typography>
                                </Stack>
                                {data?.is_payable ? (
                                    <Stack sx={{ mt: 2 }} direction="row" spacing={2}>
                                        <Button fullWidth variant="outlined" onClick={() => handleSubmit("reject")}>
                                            Reject
                                        </Button>
                                        <Button fullWidth variant="contained" onClick={() => handleSubmit("confirm")}>
                                            Pay Now
                                        </Button>
                                    </Stack>
                                ) : null}
                            </Stack>
                        </BoxDiv>
                    </Grid>
                </Grid>
            </Box>
            <Modal open={showModal} onClose={() => setShowModal(false)}>
                <Loginbox>
                    {
                        <ConfirmModalContent
                            handleModal={() => setShowModal(false)}
                            isAccepted={isAccepted}
                            dispatch={dispatch}
                            reqId={reqId}
                        />
                    }
                </Loginbox>
            </Modal>
        </>
    );
};

export default RequestDetails;
