import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Tab, Tabs } from "@material-ui/core";


import { resetAllUserTxns, listAllUserTxns } from "store/actions";
import { Stack } from "@mui/material";
import TabOne from "./tab-one";
import TabTwo from "./tab-two";

function Transactions() {
    const [tab, setTab] = useState(1);


    const handleTabs = (event, newValue) => {
        setTab(newValue);
    };

    return (
        <div>
            <Box sx={{ width: "100%", mt: 10, mb: 2 }}>
                <Stack>
                    <Tabs
                        value={tab || 1}
                        onChange={handleTabs}
                        centered
                    >
                        <Tab value={1} label="Wallet" />
                        <Tab value={2} label="External Address" />
                    </Tabs>
                </Stack>
            </Box>
            {tab === 1 ?
                (
                    <TabOne />
                ) : (
                    <TabTwo />
                )
            }

        </div>
    );
}

export default Transactions;
