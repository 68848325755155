import { Grain } from "@material-ui/icons";

import Home from "views/auth/home";
// import Landing from "views/landing";
import PaymentRequests from "views/paymentRequest";
import RequestDetails from "views/paymentRequest/requestDetails";
import Payments from "views/payments";
// import Homepage from "views/homepage";
import ProfilePage from "views/profile";
import BuyPage from "views/profile/BUY-PAGE";
import ReceivePage from "views/profile/RECEIVE-PAGE";
import SwapPage from "views/profile/SWAP-PAGE";
import TransferPage from "views/profile/TRANSFER-PAGE";
import QrHome from "views/qr";
import Transactions from "views/transactions";

var adminRoutes = [
    {
        path: "/profile",
        name: "Profile",
        icon: "fa-solid fa-user",
        iconColor: "Primary",
        component: ProfilePage,
        layout: "/app",
        visibility: true,
    },
    {
        path: "/receive/:username",
        name: "Receive",
        icon: "fa-solid fa-user",
        iconColor: "Primary",
        component: ReceivePage,
        layout: "/app",
        visibility: false,
    },
    {
        path: "/transfer",
        name: "Transfer",
        icon: "fa-solid fa-user",
        iconColor: "Primary",
        component: TransferPage,
        layout: "/app",
        visibility: false,
    },
    {
        path: "/buy",
        name: "Buy",
        icon: "fa-solid fa-user",
        iconColor: "Primary",
        component: BuyPage,
        layout: "/app",
        visibility: false,
    },
    {
        path: "/swap",
        name: "Swap",
        icon: "fa-solid fa-user",
        iconColor: "Primary",
        component: SwapPage,
        layout: "/app",
        visibility: false,
    },
    // {
    //     path: "/home",
    //     name: "home",
    //     icon: "fa-solid fa-house",
    //     iconColor: "Primary",
    //     component: Homepage,
    //     layout: "/app",
    //     visibility: true,
    // },
    {
        path: "/transactions",
        name: "Transactions",
        icon: "fa fa-history",
        iconColor: "Primary",
        component: Transactions,
        layout: "/app",
        visibility: true,
    },
    {
        path: "/payment-requests",
        name: "Payment Requests",
        icon: "fa-regular fa-credit-card",
        iconColor: "Primary",
        component: PaymentRequests,
        layout: "/app",
        visibility: true,
    },
    {
        path: "/request/:reqId",
        name: "Request",
        icon: "fa-regular fa-credit-card",
        iconColor: "Primary",
        component: RequestDetails,
        layout: "/app",
        visibility: false,
    },

    // {
    //     path: "/companies/:country?/:index",
    //     name: "Companies",
    //     icon: "fa fa-home",
    //     iconColor: "Primary",
    //     component: Companies,
    //     layout: "/app",
    //     visibility: false,
    // },
    // {
    //     path: "/price-history",
    //     name: "Stock historical data",
    //     icon: "fa fa-home",
    //     iconColor: "Primary",
    //     component: StockPriceHistory,
    //     layout: "/app",
    //     visibility: false,
    // },
    {
        divider: true,
        layout: "/app",
        visibility: true,
    },
];

var authRoutes = [
    {
        path: "/home",
        name: "Listings",
        icon: Grain,
        iconColor: "Primary",
        component: Home,
        layout: "/auth",
        visibility: true,
    },
];

var qrRoutes = [
    {
        path: ":data",
        name: "Qr",
        icon: Grain,
        iconColor: "Primary",
        component: QrHome,
        layout: "/",
        visibility: true,
    },
];

var paymentRoutes = [
    {
        path: "/:reqId",
        name: "Payment Request",
        icon: Grain,
        iconColor: "Primary",
        component: Payments,
        layout: "/payment",
        visibility: true,
    },
];

export { adminRoutes, authRoutes, qrRoutes, paymentRoutes };
