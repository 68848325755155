import * as React from "react";
import { Snackbar, Button, IconButton } from "@mui/material";
import { Close } from "@material-ui/icons";
import { history } from "utils";

export default function SimpleSnackbar({
    open = false,
    handleClose,
    message = "You have a new notification",
    openUrl = "",
}) {
    const openBtnAction = () => {
        if (openUrl) {
            handleClose();
            history.push(openUrl);
        } else {
            handleClose();
        }
    };
    const action = (
        <React.Fragment>
            <Button size="small" variant="contained" onClick={() => openBtnAction()}>
                OPEN
            </Button>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                <Close fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <div>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message={message}
                action={action}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            />
        </div>
    );
}
