import React, { useState } from "react";
import { Box, Tab, Tabs, Button, Grid,Modal } from "@material-ui/core";
import { Stack } from "@mui/material";
import styled from "styled-components";

import TabOne from "./tab-one";
import TabTwo from "./tab-two";
import NewRequest from "./newRequest";

const Loginbox = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 430px;
    outline: none;

    @media screen and (max-width: 500px) {
        width: 350px;
    }
`;

function PaymentRequests() {
    const [tab, setTab] = useState(1);
    const [showNewReq, setShowNewReq] = useState(false);

    const handleTabs = (event, newValue) => {
        setTab(newValue);
    };

    return (
        <div>
            <Grid container>
                <Grid item lg={12} xs={12}>
                    <Stack alignItems="center" sx={{mt: 4}} spacing={1}>
                        <Button
                            variant="contained"
                            style={{
                                width: "50px",
                                height: "60px",
                                borderRadius: "50%",
                                display: "grid",
                                placeItems: "center",
                            }}
                            onClick={() => setShowNewReq(true)}
                        >
                            <i className="fa fa-plus-circle" style={{ fontSize: "20px" }} />
                        </Button>
                        <b> NEW REQUEST </b>
                    </Stack>
                </Grid>
            </Grid>
            <Box sx={{ width: "100%", mt: 6 }}>
                <Stack>
                    <Tabs value={tab || 1} onChange={handleTabs} centered>
                        <Tab value={1} label="Requested" />
                        <Tab value={2} label="Received" />
                    </Tabs>
                </Stack>
            </Box>
            {tab === 1 ? <TabOne /> : <TabTwo />}

            <Modal open={showNewReq} onClose={() => setShowNewReq(false)}>
                <Loginbox>
                    <NewRequest handleModal={() => setShowNewReq(false)} />
                </Loginbox>
            </Modal>
        </div>
    );
}

export default PaymentRequests;
