import React from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { Button } from '@material-ui/core';

function SupportingStartups() {
    const startups = [
        {
            id: "00",
            name: "Funq",
            website: "https://wallet.funq.club/",
            img: "https://i.ibb.co/cvr45fK/funq.png",
            about: "Funq is an AI powered game based on financial markets globally. People can carry out predictions about cryptos and stocks for the upcomming day earn tokens.",
            description: "",
        },
        {
            id: "01",
            name: "CineTokens",
            website: "https://www.cinetokens.com/",
            img: "https://i.ibb.co/99V6LNK/IMG-20220517-131114-594-01-2.jpg",
            about: "CineTokens is a platform that lets producers tokenize their movies and these tokens are then made accessible to users to purchase, which will let them have an ownership stake in the movie.",
            description:
                "CineTokens is a blockchain project that attempts to enhance the value creation opportunities in cinema, linking to the real world by leveraging the concept of decentralization. This reaches out to every stakeholder in the cinema ecosystem and ensures larger participation by creating a new set of stakeholders in the industry.",
        },
        {
            id: "02",
            name: "Renai",
            website: "https://renai.io/",
            img: "https://i.ibb.co/qk3mjgw/huge-1.png",
            about: "Renai is a food commodities trading platform linking food production and trade with tokens.",
            description:
                "Renai connects farmers with backers to fund their farming initiative. In return, backers get ownership of their future produce.",
        },
        {
            id: "03",
            name: "ShadoPay",
            website: "https://www.shadopay.com/",
            img: "https://i.ibb.co/rwwkhPx/shadopay-logo-Cropped.png",
            about: "ShadoPay empowers local business owners to receive crypto payments with the simplest 1-click onboarding.",
            description:
                "ShadoPay is a Web3 solution empowering business owners for receiving crypto payments with the simplest   1-click onboarding. Users can receive BTC, ETH, XRP, LTC, TON and dozens of other popular ERC-20 & Solana tokens and cryptocurrencies.",
        },
    ];

    return (
        <>
            <section style={{ backgroundColor: "#000", marginTop: "1px" }}>
                <div className="container">
                    <Box sx={{ width: "100%", padding: "30px 0" }}>
                        <Stack spacing={7}>
                            <Typography variant="h5" sx={{ textAlign: "center", letterSpacing: "5px" }}>
                                STARTUPS WE  ARE POWERING
                            </Typography>
                            <Stack>
                                <Grid container spacing={4} justifyContent="center">
                                    {startups.map((e,i) => (
                                        <Grid item lg={3} md={6} xs={10} key={i}>
                                            <Box sx={{ width: "100%" }}>
                                                <Stack spacing={2} alignItems='center'>
                                                    <img src={e.img} width="70px" style={{borderRadius: "10px", border: "3px solid #ccc"}} alt=""/>
                                                    <Box sx={{ p: 2, border: "1px solid #616161", borderRadius: "10px"}}>
                                                        <Stack spacing={2} alignItems='center'>
                                                            <h5 style={{textTransform: "capitalize", color: "#fff"}}>{e.name}</h5>
                                                            <div style={{height: "160px"}}>
                                                            <Typography variant="body1" sx={{ textAlign: "center" }}>
                                                               {e?.about}
                                                            </Typography>
                                                            </div>
                                                            <Button fullWidth variant="contained" size="small" onClick={() => window.open(e.website)}>
                                                                <b>EXPLORE</b>
                                                            </Button>
                                                        </Stack>
                                                    </Box>
                                                </Stack>
                                            </Box>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Stack>
                        </Stack>
                    </Box>
                </div>
            </section>
        </>
    )
}

export default SupportingStartups