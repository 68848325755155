import React, { useState } from "react";
import { Box, Tabs, Tab } from "@material-ui/core";
import { Stack } from "@mui/material";
import styled from "styled-components";
import Transfer from "./Transfer";

const BoxDiv = styled.div`
    background-color: #181529;
    box-shadow: 0 3px 70px #000000;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 430px;
    outline: none;

    @media screen and (max-width: 600px) {
        width: 90%;
    }
`;

const TransferPage = () => {
    const [tab, setTab] = useState("email");

    const handleTabs = (event, newValue) => {
        setTab(newValue);
    };

    return (
        <>
            <Box>
                <BoxDiv>
                    <Stack sx={{ p: 3 }}>
                        <Stack spacing={2}>
                            <div style={{ textAlign: "center", fontSize: "17px", color: "white" }}>
                                <p>TRANSFER</p>
                            </div>
                            <Stack>
                                <Tabs value={tab} onChange={handleTabs} centered>
                                    <Tab value="email" label="To email" />
                                    <Tab value="wallet" label="To wallet" />
                                </Tabs>
                            </Stack>
                            <Stack>
                                <Transfer type={tab} />
                            </Stack>
                        </Stack>
                    </Stack>
                </BoxDiv>
            </Box>
        </>
    );
};

export default TransferPage;
