import React from "react";

import "./navBar.css";
import { appName } from "helpers";

function NavBar() {
    return (
        <>
            <nav>
                <div className="container">
                    <input id="nav-toggle" type="checkbox" />
                    <div className="logo">
                        <strong>
                            <a href="/">{appName}</a>
                        </strong>
                    </div>
                    <ul className="links">
                        <li><a href="/">Home</a></li>
                        <li><a href="https://business.tokenlist.club/login">Business Account</a></li>
                        <li>
                            <a href="/auth/home">
                                <button>Wallet Login</button>
                            </a>
                        </li>
                    </ul>
                    <label htmlFor="nav-toggle" className="icon-burger">
                        <div className="line" />
                        <div className="line" />
                        <div className="line" />
                    </label>
                </div>
            </nav>
        </>
    );
}

export default NavBar;
