import { toast } from "react-toastify";
import { post } from "services";
import { FETCH_ALL_PAYMENT_REQUESTS, SEND_PAYMENT_REQ, FETCH_PAYMENT_REQ_DETAILS, COMPLETE_PAYMENT_REQ } from "graphql";
import {
    TOGGLE_LOADING,
    LIST_ALL_PAYMENT_REQS,
    RESET_ALL_PAYMENT_REQS,
    RESET_PAYMENT_REQ_DETAILS,
    LIST_PAYMENT_REQ_DETAILS,
} from "store/types";

export const listAllPaymentReqs = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        const { pageSize, pageNumber, list } = getState().paymentReqs;

        let apiResponse = await post("", {
            query: FETCH_ALL_PAYMENT_REQUESTS,
            variables: {
                pageSize: pageSize,
                pageNumber: pageNumber,
                ...requestData,
            },
        });

        if (apiResponse.data.customStatus) {
            let {
                list_all_payment_requests: { data, hasMore },
            } = apiResponse.data.data;

            let updatedList = [...list, ...data];

            dispatch({
                type: LIST_ALL_PAYMENT_REQS,
                payload: {
                    list: updatedList,
                    showViewMore: hasMore,
                    pageNumber: pageNumber + 1,
                },
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetAllPaymentReqs = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_ALL_PAYMENT_REQS });
    };
};

export const sendNewPaymentRequest = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: SEND_PAYMENT_REQ,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            toast.success("Payment Request sent");
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return true;
        } else {
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return false;
        }
    };
};

export const fetchPendingReceivedPaymentReqs = () => {
    return async () => {
        let apiResponse = await post("", {
            query: FETCH_ALL_PAYMENT_REQUESTS,
            variables: {
                pageSize: 1,
                pageNumber: 1,
                type: "received",
                status: "pending",
            },
        });

        let response = [];

        if (apiResponse.data.customStatus) {
            let {
                list_all_payment_requests: { data },
            } = apiResponse.data.data;

            response = data;
        }

        return response;
    };
};

export const resetPaymentReqDetails = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_PAYMENT_REQ_DETAILS });
    };
};

export const listPaymentReqDetails = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: FETCH_PAYMENT_REQ_DETAILS,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            let {
                list_payment_request_details: { data },
            } = apiResponse.data.data;

            dispatch({
                type: LIST_PAYMENT_REQ_DETAILS,
                payload: data,
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const completePaymentRequest = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: COMPLETE_PAYMENT_REQ,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            toast.success("Payment Request completed");
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return true;
        } else {
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return false;
        }
    };
};
