import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FilledInput, InputAdornment, Select, MenuItem, Box, Button, CircularProgress as Loader } from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import { Email, Money } from "@material-ui/icons";

import { sendNewPaymentRequest, listAllPaymentReqs, resetAllPaymentReqs } from "store/actions";
function SendRequest({ handleModal }) {
    const dispatch = useDispatch();

    const { isLoading } = useSelector(({ loading }) => ({
        isLoading: loading,
    }));

    return (
        <>
            <Formik
                initialValues={{
                    email: "",
                    amount: 0,
                    network: "solana",
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string().email().required("Email address is required"),
                    amount: Yup.number().min(0.001).required("Payment Amount is required"),
                })}
                onSubmit={async (value) => {
                    const response = await dispatch(sendNewPaymentRequest(value));
                    if (response) {
                        dispatch(resetAllPaymentReqs());
                        dispatch(listAllPaymentReqs());
                    }
                    handleModal();
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
                    <form onSubmit={handleSubmit}>
                        <FilledInput
                            autoComplete="off"
                            type="email"
                            fullWidth
                            placeholder="Enter Email Address"
                            name="email"
                            defaultValue={values.email}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            startAdornment={
                                <InputAdornment position="start">
                                    <Email />
                                </InputAdornment>
                            }
                            required={true}
                            error={Boolean(touched.email && errors.email)}
                            disabled={isLoading}
                        />
                        {Boolean(touched.email && errors.email) ? (
                            <div
                                style={{
                                    display: "block",
                                    marginLeft: "10px",
                                    color: "red",
                                    fontSize: 13,
                                }}
                            >
                                {errors.email}
                            </div>
                        ) : (
                            ""
                        )}
                        <br />

                        <FilledInput
                            autoComplete="off"
                            type="number"
                            fullWidth
                            placeholder="Enter Amount"
                            name="amount"
                            defaultValue={values.amount}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            startAdornment={
                                <InputAdornment position="start">
                                    <Money />
                                </InputAdornment>
                            }
                            required={true}
                            error={Boolean(touched.amount && errors.amount)}
                            disabled={isLoading}
                        />
                        {Boolean(touched.amount && errors.amount) ? (
                            <div
                                style={{
                                    display: "block",
                                    marginLeft: "10px",
                                    color: "red",
                                    fontSize: 13,
                                }}
                            >
                                {errors.amount}
                            </div>
                        ) : (
                            ""
                        )}
                        <br />

                        <Select
                            labelId="demo-simple-select-label"
                            name="network"
                            value={values.network}
                            label="Select Network"
                            onChange={handleChange}
                            style={{ width: "100%", marginBottom: "15px" }}
                            disabled={isLoading}
                        >
                            <MenuItem value={"solana"}>Solana (Solana chain)</MenuItem>
                            <MenuItem value={"sol_usdc"}>USDC (Solana chain)</MenuItem>
                            <MenuItem value={"sol_usdt"}>USDT (Solana chain)</MenuItem>
                        </Select>

                        <Box textAlign="center" marginTop="1.5rem" marginBottom="1.5rem">
                            <Button
                                id="signin-btn"
                                color="primary"
                                variant="contained"
                                onClick={handleSubmit}
                                disabled={values.amount <= 0}
                                style={{color: values.amount <= 0 ? "grey" : ""}}
                            >
                                {isLoading ? <Loader color="secondary" size={20} /> : "Send Request"}
                            </Button>
                        </Box>
                    </form>
                )}
            </Formik>
        </>
    );
}

export default SendRequest;
