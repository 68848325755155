import React, { useEffect } from "react";
import ExpandableListView from "components/ExpandableListView/ExpandableListView";
import moment from "moment";
import { EmptyBox } from "components/StyledComponents";
import { useDispatch, useSelector } from "react-redux";
import { resetAllUserTxns } from "store/actions";
import { listAllUserTxns } from "store/actions";
import { getBlockchainName } from "utils";
import { getBlockchainNetwork } from "utils";

function TabOne() {
    const dispatch = useDispatch();

    const { txnsData, showViewMore } = useSelector(({ user: { txns } }) => ({
        txnsData: txns.list,
        showViewMore: txns.showViewMore,
    }));

    useEffect(() => {
        dispatch(resetAllUserTxns());
        fetchUserTxns();
    }, []);

    const fetchUserTxns = () => {
        dispatch(listAllUserTxns());
    };

    const colorIndicator = (element, arr = [], style = {}) => {
        arr.map((e) => {
            if (element[e.field] === e.value) {
                style = { color: e.color };
            }
            return null;
        });
        return style;
    };

    return (
        <>
                {txnsData ? (
                        <ExpandableListView
                            pairs={[
                                { key: "TXN ID", value: "txn_id" },
                                {
                                    key: "Status",
                                    render: (e) => (
                                        <div
                                            style={colorIndicator(e, [
                                                { field: "status", value: "success", color: "#6bc654" },
                                                { field: "status", value: "failed", color: "red" },
                                                { field: "status", value: "pending", color: "#a1a1a1" },
                                            ])}
                                        >
                                            {e.status}
                                        </div>
                                    )
                                },
                                {
                                    key: "Amount",
                                    render: (rowData) => `${rowData?.amount} ${rowData?.currency.toUpperCase()}`,
                                },
                                { key: "Network", render: (rowData) => getBlockchainName(rowData?.chain_network) },
                                { key: "Type", value: "type" },
                                {
                                    key: "Created At",
                                    render: (rowData) => moment.unix(rowData?.created_at).format("DD-MM-YYYY hh:mm A"),
                                },
                                { key: "User", render: (rowData) => (rowData?.to_uid ? rowData.to_uid?.email : "-") },
                                {
                                    key: "User Wallet",
                                    render: (rowData) => (
                                        <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                                            {rowData?.to_user_wid ?? "-"}
                                        </div>
                                    )
                                },
                            ]}
                            leftTitle={[
                                {
                                    key: "chain_network",
                                    render: (e) => `${getBlockchainName(e?.chain_network)}`,
                                },
                                {
                                    key: "created_at",
                                    render: (e) => (
                                        <>
                                            {moment.unix(e?.created_at).format("DD-MM-YYYY hh:mm A")}
                                        </>
                                    )
                                }
                            ]}
                            rightTitle={{
                                main: {
                                    render: (e) => (
                                        <b
                                            style={colorIndicator(e, [
                                                { field: "type", value: "received", color: "#6bc654" },
                                                { field: "type", value: "transferred", color: "red" },
                                            ])}
                                        >
                                            {e?.amount} {e?.currency.toUpperCase()}
                                        </b>
                                    )
                                },
                                sub: {
                                    render: (e) => (
                                        <div
                                            style={colorIndicator(e, [
                                                { field: "status", value: "success", color: "#6bc654" },
                                                { field: "status", value: "failed", color: "red" },
                                                { field: "status", value: "pending", color: "#a1a1a1" },
                                            ])}
                                        >
                                            {e.status}
                                        </div>
                                    )
                                }
                            }}
                            data={txnsData}
                            iconImg={{render: (e) => `${getBlockchainNetwork(e.chain_network)}`}}
                            showViewMoreButton={showViewMore}
                            viewMoreButtonClick={fetchUserTxns}
                        />
                ) : (
                    <EmptyBox>No data found</EmptyBox>
                )}
        </>
    );
}

export default TabOne;
