import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Box, Button, FilledInput, FormControl, FormLabel, MenuItem, ownerWindow, Select, Modal } from "@material-ui/core";
import { Stack } from "@mui/material";
// import { cryptoCurrencies } from "datas/crypto-currencies";
// import { fiatCurrencies } from "datas/fiat-currencies";
// import { createRampayOrder, resetRampayOrderId } from "store/actions";
import { securoFiatCurrencies } from "datas/securoFiatCurrencies";
import { createSecuroPaymentRequest } from "store/actions";
import { securoCryptoCurrencies } from "datas/securoCryptoCurrencies";

// const API_KEY = process.env.REACT_APP_RAMPAY_API_KEY;

function BuyingPage({ dispatch }) {
    const [wallet, setWallet] = useState("");
    const [crypto, setCrypto] = useState("SOL");
    const [fiat, setFiat] = useState("EUR");
    const [fiatAmount, setFiatAmount] = useState(30);
    const [paymentMethodes, setPaymentMethodes] = useState(securoFiatCurrencies[0].paymentOptions);
    const [selectedPmethode, setSelectedPmethode] = useState(securoFiatCurrencies[0].paymentOptions[0].id);
    const [showModal, setShowModal] = useState(false);
    const [magikURL, setMagikURL] = useState("");

    const { rampayUserId, rampayOrderId, userProfile } = useSelector(({ user: { rampayUid, rampayOrderId, profile } }) => ({
        rampayUserId: rampayUid,
        rampayOrderId,
        userProfile: profile,
    }));

    useEffect(() => {
        setWallet(
            userProfile?.wallets ? userProfile.wallets.filter((e) => e.network === "solana")[0]?.wallet_address ?? "" : ""
        );
    }, []);

    const handleCryptoChange = (event) => {
        setCrypto(event.target.value);
    };

    const handleFiatChange = (event) => {
        let filtered = securoFiatCurrencies.filter((e) => event.target.value === e.symbol);
        setFiat(event.target.value);
        setPaymentMethodes(filtered[0].paymentOptions);
        setSelectedPmethode(
            filtered[0].paymentOptions.filter((j) => j.id === "credit_debit_card").length
                ? "credit_debit_card"
                : filtered[0].paymentOptions[0].id
        );
        // setFiatAmount(filtered[0].paymentOptions[0].minAmount)
        // setFiatAmount(30);
    };

    const handleAMountChange = (event) => {
        setFiatAmount(event.target.value);
    };

    const handlePmethodeChange = async (event) => {
        await setSelectedPmethode(event.target.value);
        let filtered = await paymentMethodes.filter((e) => selectedPmethode === e.id);
        // setFiatAmount(filtered[0].minAmount);
    };

    // const handleWalletChange = (event) => {
    //     setWallet(event.target.value);
    // };

    const handleContinue = async () => {
        if (!["polygon_usdt", "polygon_usdc"].includes(crypto)) {
            let filtered = paymentMethodes.filter((e) => selectedPmethode === e.id);
            if (fiatAmount < filtered[0].minAmount) {
                return toast.error(`Minimum amount is ${filtered[0].minAmount}`);
            } else if (wallet === "") {
                return toast.error("Please enter wallet address");
            }

            const data = {
                network: "solana",
                walletAddress: wallet,
                fiatCurrency: fiat,
                fiatAmount: parseFloat(fiatAmount),
                cryptoCurrency: crypto,
                paymentMethod: selectedPmethode,
                trxType: "Buy",
            };
            // setBuyModal(false);
            let url = await dispatch(createSecuroPaymentRequest({ data: data }));
            if (url) {
                window.open(url.url);
            } else {
                return toast.error("Failed to create order!, Try again");
            }
        } else {
            const polygonWallet = userProfile?.wallets
                ? userProfile.wallets.filter((e) => e.network === "polygon_matic")[0]?.wallet_address ?? ""
                : "";

            const magikLink = `https://onramp.magiklabs.io/?apiKey=8uGqXCj24BLGhqGP-6MO5MXenNCrPO8w
            &tokens=[{%22address%22:%22${
                crypto === "polygon_usdc"
                    ? "0x2791bca1f2de4661ed88a30c99a7a9449aa84174"
                    : "0xc2132d05d31c914a87c6611c10748aeb04b58e8f"
            }%22,%22chainId%22:%22polygon-pos%22,%22depositAddress%22:%22${polygonWallet}%22}]
            &fiatAmount=${fiatAmount}
            &userEmail=${userProfile?.email}
            &primaryColor=%23D480FF
            &widgetType=paymentLink
            &defaultCountry=IN`;

            setMagikURL(magikLink);

            // if (magikLink) {
            //     window.open(magikLink);
            // } else {
            //     return toast.error("Failed to create order!, Try again");
            // }
            setShowModal(true);
        }

        // const data = {
        //     order: {
        //         cfpmId: 53,
        //         cryptoCurrencyId: crypto,
        //         fiatAmount: fiatAmount,
        //         walletAddress: wallet,
        //     },
        //     userId: rampayUserId.uid,
        // };
        // dispatch(resetRampayOrderId());
        // dispatch(createRampayOrder({ data: data }));
        // if (rampayOrderId?.order_id) {
        //     window.open(
        //         `https://exchange.rampay.io?orderId=${rampayOrderId?.order_id}&apiKey=${API_KEY}`
        //         // `https://sandbox.rampay.io/exchange?orderId=${rampayOrderId?.order_id}&apiKey=77c83f1b-3999-4715-8c02-7e9911d7df99`
        //     );
        // } else {
        //     return toast.error("Failed to create order!, Try again");
        // }
    };

    return (
        <>
            <Box sx={{ p: 3 }}>
                {/* <div style={{ marginBottom: "10px", textAlign: "center", fontSize: "18px", color: "#000" }}>
                    <p>Buy Here</p>
                </div> */}
                <Stack spacing={2}>
                    <Stack>
                        <FormLabel>Select Crypto Currency</FormLabel>
                        <FormControl fullWidth>
                            <Select fullWidth size="small" value={crypto} onChange={handleCryptoChange}>
                                {securoCryptoCurrencies.map((e, i) => (
                                    <MenuItem value={e.display_chain === "Polygon chain" ? e.name : e.code} key={i}>
                                        {e.code} ({e.display_chain})
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Stack>
                    {!["polygon_usdt", "polygon_usdc"].includes(crypto) ? (
                        <Stack>
                            <FormLabel>Select Fiat Currency</FormLabel>
                            <FormControl fullWidth>
                                <Select fullWidth size="small" value={fiat} onChange={handleFiatChange}>
                                    {securoFiatCurrencies.map((e, i) => (
                                        <MenuItem value={e.symbol} key={i}>
                                            {e.name} ({e.symbol})
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Stack>
                    ) : null}
                    <Stack>
                        <FormLabel>Fiat Amount</FormLabel>
                        <FilledInput value={fiatAmount} onChange={handleAMountChange} />
                    </Stack>
                    {!["polygon_usdt", "polygon_usdc"].includes(crypto) ? (
                        <Stack>
                            <FormLabel>Select Payment methode</FormLabel>
                            <FormControl fullWidth>
                                <Select fullWidth size="small" value={selectedPmethode} onChange={handlePmethodeChange}>
                                    {paymentMethodes.map((e, i) => (
                                        <MenuItem value={e.id} key={i}>
                                            {e.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Stack>
                    ) : null}

                    {/* <Stack>
                        <FormLabel>{crypto} Wallet Address</FormLabel>
                        <FilledInput value={wallet} onChange={handleWalletChange} />
                    </Stack> */}
                    <Stack>
                        <Button variant="contained" onClick={handleContinue}>
                            Continue
                        </Button>
                    </Stack>
                </Stack>
                <Modal
                    open={showModal}
                    onClose={() => setShowModal(false)}
                    style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                >
                    <iframe src={magikURL} height={700} width={450}></iframe>
                </Modal>
            </Box>
        </>
    );
}

export default BuyingPage;
