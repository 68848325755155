import styled from 'styled-components'


export const OutlinedButton = styled.button`
    border: 1px solid #B5EAD7;
    border-radius: 10px;
    padding: 10px 20px;
    text-decoration: none;
    color: #B5EAD7;
    background-color: #161A31;
    transition: 0.3s;
    font-size: 1em;
    font-weight: bold;
    cursor: pointer;
    outline: none;

    &:hover {
        color: #000;
        background-color: #B5EAD7;
    }
`;

export const ContainedButton = styled.button`
    border: 1px solid #B5EAD7;
    border-radius: 10px;
    padding: 10px 20px;
    text-decoration: none;
    color: #000;
    background-color: #B5EAD7;
    transition: 0.3s;
    font-size: 1em;
    font-weight: bold;
    cursor: pointer;
    outline: none;

    &:hover {
        color: #000;
        background-color: #B5EAD7;
    }
`;